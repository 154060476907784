import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { ResponseData } from "src/app/models/response";
import { OmiCallStatus, OmiCallViewType, SwitchBoardCode } from "src/app/shared/Enums/switchboard";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})


export class OmiCallService {

  private static infoUserOmi: OmiCallRegister = null;
  private static isShowOmiViewDial: boolean = false;
  private static isActive: boolean = null;
  private static typeView: OmiCallViewType = OmiCallViewType.DIAL;

  private static callFromNumber: string = null;
  private static callToNumber: string = null;
  private static timeCall: string = null;

  private static dataRecive: OmiCallDataRecive = null;

  private static countLoadInit = 0;

  constructor(
    public httpClient: HttpClient,
  ) {
  }


  private omiDataSubject = new BehaviorSubject<OmicallData>({ isShowOmiViewDial: false, typeView: OmiCallViewType.DIAL });
  private isShowOmiViewDialSubject = new BehaviorSubject<boolean>(false);
  private isActiveOmiViewDialSubject = new BehaviorSubject<boolean>(false);
  private setPhoneToCallSubject = new BehaviorSubject<string>("");
  private isInitOmiSDK = new BehaviorSubject<boolean>(null);

  omiDataSubject$ = this.omiDataSubject.asObservable();
  isShowOmiViewDialSubject$ = this.isShowOmiViewDialSubject.asObservable();
  isActiveOmiViewDialSubject$ = this.isActiveOmiViewDialSubject.asObservable();
  setPhoneToCallSubject$ = this.setPhoneToCallSubject.asObservable();
  isInitOmiSDK$ = this.isInitOmiSDK.asObservable();

  setIsShowOmiViewDialSubject() {
    OmiCallService.isShowOmiViewDial = !OmiCallService.isShowOmiViewDial;
    this.isShowOmiViewDialSubject.next(OmiCallService.isShowOmiViewDial);
    // this.sendOmiDataSubject({
    //   isShowOmiViewDial: OmiCallService.isShowOmiViewDial,
    //   typeView: OmiCallService.typeView,
    //   callFromNumber: OmiCallService.callFromNumber
    // })
  }

  setShowDialWithPhone(phone: string) {
    OmiCallService.isShowOmiViewDial = true;
    this.isShowOmiViewDialSubject.next(OmiCallService.isShowOmiViewDial);
    this.setPhoneToCallSubject.next(phone);
  }

  setDataRecive(dataRecive: OmiCallDataRecive) {
    OmiCallService.dataRecive = dataRecive;
  }

  ClickToCall(phone, dataRecive: OmiCallDataRecive) {
    OmiCallService.dataRecive = dataRecive;
    omiSDK.makeCall(phone);
  }

  sendOmiDataSubject(data: OmicallData) {
    this.omiDataSubject.next(data);
  }

  onCloseAfterCall() {
    OmiCallService.isShowOmiViewDial = false;
    OmiCallService.typeView = OmiCallViewType.DIAL;
    // this.isShowOmiViewDialSubject.next(OmiCallService.isShowOmiViewDial);
    this.sendOmiDataSubject({
      isShowOmiViewDial: OmiCallService.isShowOmiViewDial,
      typeView: OmiCallService.typeView,
    })
  }


  setActive() {
    OmiCallService.isActive = true;
    this.isActiveOmiViewDialSubject.next(OmiCallService.isActive);
  }

  getActive() {
    return OmiCallService.isActive;
  }

  unregister() {
    if (OmiCallService.isActive) {
      omiSDK.unregister();
      OmiCallService.isActive = false;
      OmiCallService.infoUserOmi = null;
      OmiCallService.isShowOmiViewDial = false;
      this.isShowOmiViewDialSubject.next(OmiCallService.isShowOmiViewDial);
      this.isActiveOmiViewDialSubject.next(OmiCallService.isActive);
    }
  }

  setUpInit(info: OmiCallRegister) {
    if (OmiCallService.isActive) {
      OmiCallService.isActive = false;
      OmiCallService.infoUserOmi = null;
      OmiCallService.isShowOmiViewDial = false;
      this.isShowOmiViewDialSubject.next(OmiCallService.isShowOmiViewDial);
      this.isActiveOmiViewDialSubject.next(OmiCallService.isActive);
      omiSDK.unregister().then(() => {
        OmiCallService.infoUserOmi = info;
        this.InitDataRecive();
        this.omiSDK_Init(info);
      })
    } else {
      OmiCallService.infoUserOmi = info;
      this.InitDataRecive();
      this.omiSDK_Init(info);
    }
  }

  omiSDK_Init(info) {
    if (OmiCallService.countLoadInit < 3) {
      omiSDK.init(this.ConfigOmiCall, () => {
        omiSDK.register(info)
          .then((data) => {
            console.log("register success:", data)
            if (data?.code === 'ok') {
              this.isInitOmiSDK.next(true);
              this.setActive();
            }
            if (data == true) {
              this.setActive();
              this.isInitOmiSDK.next(true);
            }
          })
          .catch(error => {
            OmiCallService.countLoadInit++;
            console.log('register error:', error)
            this.omiSDK_Init(info);
          });
      })
    } else {
      this.isInitOmiSDK.next(false);
      console.log('register error:', 'over count load init')
      return;
    }
  }


  private ConfigOmiCall = {
    debug: true,
    busy: false,
    language: 'vi',
    ringtoneVolume: 0.5,
    options: {
      hideCallButton: true
    },
    callbacks: {
      register: (data) => {
        console.log('register:', data);
        // Sự kiện xảy ra khi trạng thái kết nối tổng đài thay đổi
      },
      connecting: (data) => {
        console.log('connecting:', data);
        // Sự kiện xảy ra khi bắt đầu thực hiện cuộc gọi ra
        this.connectingCallBack(data);
      },
      invite: (data) => {
        // Sự kiện xảy ra khi có cuộc gọi tới
        OmiCallService.dataRecive.PartnerData = JSON.stringify(data);
        this.SendDataToServer(OmiCallService.dataRecive);

        this.inviteCallBack(data);
      },
      inviteRejected: (data) => {
        console.log('inviteRejected:', data);
        // Sự kiện xảy ra khi có cuộc gọi tới, nhưng bị tự động từ chối
        // trong khi đang diễn ra một cuộc gọi khác
      },
      ringing: (data) => {
        console.log('ringing:', data);
        // Sự kiện xảy ra khi cuộc gọi ra bắt đầu đổ chuông
        OmiCallService.dataRecive.PartnerData = JSON.stringify(data);
        this.SendDataToServer(OmiCallService.dataRecive);

        this.ringingCallBack(data);
      },
      accepted: (data) => {
        console.log('accepted:', data);
        // Sự kiện xảy ra khi cuộc gọi vừa được chấp nhận
        this.accpectedCallBack(data);
      },
      incall: (data) => {
        // console.log('incall:', data);
        // Sự kiện xảy ra mỗi 1 giây sau khi cuộc gọi đã được chấp nhận
        this.inCallCallBack(data);
      },
      acceptedByOther: (data) => {
        console.log('acceptedByOther:', data);
        // Sự kiện dùng để kiểm tra xem cuộc gọi bị kết thúc
        // đã được chấp nhận ở thiết bị khác hay không
        this.sendOmiDataSubject({
          isShowOmiViewDial: false,
          typeView: OmiCallViewType.DIAL,
        })
      },
      ended: (data) => {
        console.log('ended:', data);
        // Sự kiện xảy ra khi cuộc gọi kết thúc
        this.endedCallBack(data)
        this.InitDataRecive();
      },
      holdChanged: (status) => {
        // Sự kiện xảy ra khi trạng thái giữ cuộc gọi thay đổi
        console.log('on hold:', status);
      },
      saveCallInfo: (data) => {
        // Sự kiện xảy ra khi cuộc gọi đã có đổ chuông hoặc cuộc gọi tới, khi user có nhập note input mặc định hoặc form input custom
        console.log('on save call info:', data);
      },
    },
  }


  private inviteCallBack = (data) => {
    OmiCallService.isShowOmiViewDial = true;
    OmiCallService.typeView = OmiCallViewType.IN_COMMING;
    OmiCallService.callFromNumber = data?.remoteNumber;
    OmiCallService.timeCall = data?.startTimeTxt
    this.sendOmiDataSubject(this.convertDataOmiCall(data));
  }

  private endedCallBack = (data) => {
    OmiCallService.isShowOmiViewDial = true;
    this.sendOmiDataSubject(this.convertDataOmiCall(data));
  }

  private connectingCallBack = (data) => {
    OmiCallService.isShowOmiViewDial = true;
    OmiCallService.typeView = OmiCallViewType.OUT_COMMING;
    this.sendOmiDataSubject(this.convertDataOmiCall(data));
  }

  private ringingCallBack = (data) => {
    OmiCallService.isShowOmiViewDial = true;
    OmiCallService.typeView = OmiCallViewType.OUT_COMMING;
    this.sendOmiDataSubject(this.convertDataOmiCall(data));
  }

  private accpectedCallBack = (data) => {
    this.sendOmiDataSubject(this.convertDataOmiCall(data));
  }

  private inCallCallBack = (data) => {
    this.sendOmiDataSubject(this.convertDataOmiCall(data));
  }

  private convertDataOmiCall(data: any): OmicallData {
    return {
      isShowOmiViewDial: OmiCallService.isShowOmiViewDial,
      typeView: OmiCallService.typeView,
      callFromNumber: OmiCallService.callFromNumber,
      startTimeTxt: data?.startTimeTxt,
      endCause: data?.endCause,
      ringing: data?.ringing,
      ringingTxt: data?.ringingTxt,
      status: data?.status,
      statusTxt: data?.statusTxt,
      callToNumber: data?.sipNumber,
      phoneShow: data?.remoteNumber
    }
  }

  resetOmiCall() {
    if (OmiCallService.isActive) {
      omiSDK.unregister();
      OmiCallService.isActive = false;
      OmiCallService.infoUserOmi = null;
      OmiCallService.isShowOmiViewDial = false;
      OmiCallService.typeView = OmiCallViewType.DIAL;
      this.isShowOmiViewDialSubject.next(OmiCallService.isShowOmiViewDial);
      this.isActiveOmiViewDialSubject.next(OmiCallService.isActive);
    }
  }

  private SendDataToServer(data: OmiCallDataRecive) {
    const url = `${environment.SERVICE_API_SWITCHBOARD}api/TongDaiLichSuCuocGoi/TaoLichSuCuocGoi`;
    return this.httpClient.post<ResponseData>(url, data).toPromise();
  }

  private InitDataRecive() {
    OmiCallService.dataRecive = {
      SwitchBoardCode: SwitchBoardCode.OmiCall,
      UserConnectivePropName1: 'domain-fushion',
      UserConnectivePropValue1: OmiCallService.infoUserOmi?.domain,
      PartnerUserCallName: OmiCallService.infoUserOmi?.username,
      CrmUnitId: null,
      PartnerData: null,
      PropName1: null,
      PropValue1: null,
      PropName2: null,
      PropValue2: null,
      PropName3: null,
      PropValue3: null,
      PropName4: null,
      PropValue4: null
    }
  }
}

export interface OmiCallRegister {
  domain: string,
  username: string,
  password: string,
}


export type OmicallData = {
  typeView: OmiCallViewType;
  isShowOmiViewDial: boolean;
  startTimeTxt?: string,
  callToNumber?: string,
  callFromNumber?: string,
  ringing?: number,
  ringingTxt?: string,
  status?: OmiCallStatus,
  statusTxt?: string,
  endCause?: string,
  phoneShow?: string,
}


export type OmiCallDataRecive = {
  SwitchBoardCode?: string,
  CrmUnitId?: string,
  PartnerData?: string,
  PartnerUserCallName?: string,
  UserConnectivePropName1?: string,
  UserConnectivePropValue1?: string,
  PropName1?: string,
  PropValue1?: string,
  PropName2?: string,
  PropValue2?: string,
  PropName3?: string,
  PropValue3?: string,
  PropName4?: string,
  PropValue4?: string,
  PropName5?: string,
  PropValue5?: string
}

