import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../base.service';
import { UserLogin } from '../../models/userlogin';
import { StorageService } from '../storage.service';
import { STORAGE_KEY } from '../../constants/config';
import { ResponseData } from 'src/app/models/response';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  public static currentUser: UserLogin;
  public static permision: Array<String> = [];

  refreshing = false;
  user$: BehaviorSubject<ResponseData> = new BehaviorSubject<ResponseData>(
    null
  );
  private reloadDataSubject = new BehaviorSubject<boolean>(false);
  reloadData$ = this.reloadDataSubject.asObservable();

  constructor(
    public httpClient: HttpClient,
    private storageService: StorageService
  ) {
    super(httpClient, `${environment.CORE_API}api/User`);
  }

  getUserLogin(): UserLogin {
    var user = this.storageService.get(STORAGE_KEY.USER_INFO);
    if (user) {
      return new UserLogin(user);
    } else {
      return new UserLogin({});
    }
  }

  getUserInfo(): Promise<ResponseData> {
    const url = `${this.table}/GetUserInfo`;
    return this.httpClient.post<ResponseData>(url, {}).toPromise();
  }

  GetAllByIdDonVi(id): Promise<ResponseData> {
    const url = `${this.table}/GetAllByIdDonVi/${id}`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  resetTopic(body): Observable<ResponseData> {
    const url = `${this.table}/ResetTopic`;
    return this.httpClient.post<ResponseData>(url, body);
  }

  TimKiemTheoRoleVaKeyword(body): Observable<ResponseData> {
    const url = `${this.table}/TimKiemTheoRoleVaKeyword`;
    return this.httpClient.post<ResponseData>(url, body);
  }

  SearchNGTByKeyword(body) {
    const url = `${environment.CORE_API}api/User/SearchNGTByKeyword`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  SearchByKeyword_CN(body) {
    const url = `${environment.CORE_API}api/User/SearchByKeyword_CN`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  SearchByKeyword_DV(body) {
    const url = `${environment.CORE_API}api/User/SearchByKeyword_DV`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  GetAllNhanVienChiNhanh(): Observable<ResponseData> {
    const url = `${this.table}/GetAllNhanVienChiNhanh`;
    return this.httpClient.get<ResponseData>(url);
  }

  GetNhanVienByNhomNV() {
    const url = `${environment.CORE_API}api/User/GetNhanVienByNhomNV`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  LayNhanVienTheoChiNhanh(idChiNhanh) {
    const url = `${environment.CORE_API}api/User/LayNhanVienTheoChiNhanh/${idChiNhanh}`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  LayTatCaNhanVienTheoChiNhanh() {
    const url = `${environment.CORE_API}api/User/LayTatCaNhanVienTheoChiNhanh`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  HoaHong_LayTatCaNhanVienTheoChiNhanh() {
    const url = `${environment.CORE_API}api/User/HoaHong_LayTatCaNhanVienTheoChiNhanh`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  GetInfoByQRCode(body) {
    const url = `${environment.CORE_API}api/User/GetInfoByQRCode`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  XepNhanVien_LayTatCaNhanVienTheoChiNhanh(idChiNhanh) {
    const url = `${environment.CORE_API}api/User/XepNhanVien_LayTatCaNhanVienTheoChiNhanh/${idChiNhanh}`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  XepNhanVien_LayTatCaRoleVaoManThuNgan(idChiNhanh) {
    const url = `${environment.CORE_API}api/User/XepNhanVien_LayTatCaRoleVaoManThuNgan/${idChiNhanh}`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }
  ClearCurrentUser() {
    this.refreshing = false;
    if (UserService.currentUser) {
      UserService.currentUser = null;
      UserService.permision = [];
    }
  }

  getCacheUserInfo(): UserLogin {
    return UserService.currentUser;
  }

  getCurrentUser(): Observable<UserLogin> {
    if (UserService.currentUser) {
      return of(UserService.currentUser);
    } else {
      if (!this.refreshing) {
        this.refreshing = true;
        this.user$.next(null);

        const url = `${this.table}/GetUserInfo`;
        return this.post<ResponseData>(url, {}).pipe(
          map((res: ResponseData) => {
            this.refreshing = false;

            this.user$.next(res);
            if (res.success) {
              UserService.currentUser = res.data;
              return res.data;
            } else {
              return null;
            }
          }),
          finalize(() => {
            this.refreshing = false;
          })
        );
      } else {
        return this.user$.pipe(
          map((res: ResponseData) => {
            if (res && res.success) {
              return res.data;
            } else {
              return null;
            }
          })
        );
      }
      // const url = `${this.table}/GetUserInfo`;
      // return this.post<ResponseData>(url, {}).pipe(
      //   tap((res: ResponseData) => {
      //     UserService.currentUser = res.data;
      //   }),
      //   map((res: ResponseData) => {
      //     if (res.success) {
      //       return res.data;
      //     } else {
      //       return null;
      //     }

      //   })
      // );
    }
  }

  getPermisiontUser(): Observable<String[]> {
    if (UserService.permision && UserService.permision.length > 0) {
      return of(UserService.permision);
    } else {
      const url = `${this.table}/GetPermisonUserInfo`;
      return this.post<ResponseData>(url, {}).pipe(
        tap((res: ResponseData) => {
          UserService.permision = res.data;
        }),
        map((res: ResponseData) => {
          if (res.success) {
            return res.data;
          } else {
            return [];
          }
        })
      );
    }
  }

  GetListNhanVienRoleSale() {
    const url = `${environment.CORE_API}api/User/GetListNhanVienRoleSale`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  DoiMatKhau(model): Promise<ResponseData> {
    const url = `${this.table}/DoiMatKhau`;
    return this.httpClient.post<ResponseData>(url, model).toPromise();
  }

  getThongTinCaNhan(): Promise<ResponseData> {
    const url = `${this.table}/GetThongTinCaNhan_Encrypt`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  CapNhatThongTinCaNhan(model): Promise<ResponseData> {
    const url = `${this.table}/CapNhatThongTinCaNhan`;
    return this.httpClient.post<ResponseData>(url, model).toPromise();
  }

  getDonViByUserName(username): Promise<ResponseData> {
    const url = `${environment.CORE_API}api/QuanTriArea/User/GetDonViChiNhanhByUser?u=${username}`;
    return this.get<ResponseData>(url).toPromise();
  }

  doiChiNhanh(model): Promise<ResponseData> {
    const url = `${environment.CORE_API}api/User/DoiChiNhanh`;
    return this.post<ResponseData>(url, model).toPromise();
  }

  userDoiMatKhau(model): Promise<ResponseData> {
    const url = `${this.table}/UserDoiMatKhau`;
    return this.httpClient.post<ResponseData>(url, model).toPromise();
  }

  GetAllThuNganChiNhanh() {
    const url = `${environment.CORE_API}api/User/GetAllThuNganChiNhanh`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  GetListNhanVienRoleSaleUnderManagement(payload) {
    const url = `${environment.CORE_API}api/User/GetListNhanVienRoleSaleUnderManagement`;
    return this.httpClient.post<ResponseData>(url, payload).toPromise();
  }

  reloadDataChiNhanhHeader() {
    this.reloadDataSubject.next(true);
  }

  GetAllNhanVienKhoChiNhanh() {
    const url = `${environment.CORE_API}api/User/GetAllNhanVienKhoChiNhanh`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }
}
