<nz-card *ngIf="dataOmiCall.typeView == 'DIAL'" class="omi-view-card">
  <div class="view-dial">
    <div class="phone-to-call-form">
      <div class="phone-to-call-logo-form-left">
        <!-- <div class="phone-to-call-number">0328869500</div>
        <div class="phone-to-call-brand">
          <img class="phone-to-call-brand-image"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Viettel_logo_2021.svg/1024px-Viettel_logo_2021.svg.png"
            alt="" />
        </div> -->
        <div class="phone-to-call-logo">
          <img class="phone-to-call-logo-image" src="../../../../assets/images/logo/logo-sanbox.png" alt="" />
        </div>
        <div (click)="onCloseByButton()" class="dial-icon-close"
          style="height: 35px; display: flex; align-items: center;">
          <span style="font-size: 20px;" nz-icon nzType="close" nzTheme="outline"></span>
        </div>
      </div>
      <!-- <div class="phone-to-call-form-right">
        <span nz-icon nzType="right" nzTheme="outline"></span>
      </div> -->
    </div>
    <input  [nzSize]="'small'" nz-input class="input-phone" placeholder="Nhập số điện thoại" [(ngModel)]="numberPhone" type="text"
      (keydown)="onKeydown($event)" />

    <div class="dial-pad">
      <div class="dial-pad-row">
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('1')">1</span></div>
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('2')">2</span></div>
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('3')">3</span></div>
      </div>
      <div class="dial-pad-row">
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('4')">4</span></div>
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('5')">5</span></div>
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('6')">6</span></div>
      </div>
      <div class="dial-pad-row">
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('7')">7</span></div>
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('8')">8</span></div>
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('9')">9</span></div>
      </div>
      <div class="dial-pad-row">
        <div class="dial-pad-number"><span class="number-pick" style="font-size: 16px; color:#F4D03F"
            (click)="onClearOne()"><span nz-icon nzType="arrow-left" nzTheme="outline"></span></span></div>
        <div class="dial-pad-number"><span class="number-pick" (click)="onClickNumber('0')">0</span></div>
        <div class="dial-pad-number"><span class="number-pick" style="font-size: 16px; color:#E74C3C"
            (click)="onClearAll()"><span nz-icon nzType="close" nzTheme="outline"></span></span></div>
      </div>

      <div class="dial-pad-row" style="margin-top:20px; justify-content: center">
        <!-- <div class="dial-pad-number"><span class="number-pick" (click)="onClearOne()"><span nz-icon nzType="history"
              nzTheme="outline"></span></span></div> -->
        <div class="dial-pad-call-button"><span class="call-button" (click)="onCall()"><span nz-icon nzType="phone"
              nzTheme="outline"></span></span></div>
        <!-- <div class="dial-pad-number"><span class="number-pick" (click)="onClearAll()"><span nz-icon nzType="team"
              nzTheme="outline"></span></span></div> -->
      </div>
    </div>
  </div>
</nz-card>

<nz-card
  *ngIf="dataOmiCall.typeView == 'CALLING' || dataOmiCall.typeView == 'OUT_COMMING' || dataOmiCall.typeView == 'IN_COMMING'"
  class="omi-view-card-calling">
  <div class="header-call">
    <div>
      <img *ngIf="dataOmiCall.typeView == 'IN_COMMING'" style="margin-right: 10px; height:15px; width:15px;"
        [src]="'../../../../assets/icons/switchboard/in_comming_white.png'" alt="" />
      <img *ngIf="dataOmiCall.typeView == 'OUT_COMMING'" style="margin-right: 10px; height:15px; width:15px;"
        [src]="'../../../../assets/icons/switchboard/out_comming_white.png'" alt="" />
      <span style="margin-right: 10px;">{{dataOmiCall?.startTimeTxt}}</span>
      <span>{{dataOmiCall?.callToNumber}}</span>
    </div>
    <div (click)="onCloseByButton()" class="dial-icon-close" style="height: 35px; display: flex; align-items: start;">
      <span style="font-size: 20px;" nz-icon nzType="close" nzTheme="outline"></span>
    </div>
  </div>
  <div class="body-call">
    <ng-container *ngIf="isShowDesc == false">
      <div style="height:200px">
        <div style="height:30px">
        </div>
        <div class="info-call">
          <div class="info-image-box">
            <img [ngClass]="dataOmiCall?.status != 'ended' ?'info-image info-image-ringing' :'info-image' "
              [src]="infoKhachHang?.avatarUrl | image "
              onerror="this.src='../../../assets/icons/switchboard/avatar_default.jpg'" alt="" />
          </div>
          <div class="info-number">
            {{dataOmiCall?.phoneShow ?? 'Không xác định'}}
          </div>
          <div class="info-name">
            {{infoKhachHang?.tenKhachHang ?? 'Không xác định'}}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isShowDesc == true">
      <div style="height:200px">
        <textarea style="resize: none; margin-top:10px" type="text" [rows]="8" [(ngModel)]="descriptionText"
          placeholder="Nhập ghi chú" nz-input>dsads</textarea>
      </div>
    </ng-container>
    <div style="height:50px; margin: 0 20px;">
      <div class="options-call"
        *ngIf="dataOmiCall.status == 'connected' || dataOmiCall.status == 'hold' || dataOmiCall.status == 'ended'">
        <div *ngIf="!isMute &&  dataOmiCall.status != 'ended'" class="button-options-call"
          style="width:10px; display:flex; justify-content:center" (click)="onChangeMicro(true)">
          <i class="fa-solid fa-microphone " style="color: #ffffff;font-size:20px;"></i>
        </div>
        <div *ngIf="isMute  &&  dataOmiCall.status != 'ended'" class="button-options-call"
          style="width:10px; display:flex; justify-content:center" (click)="onChangeMicro(false)">
          <i class="fa-solid fa-microphone-slash " style="color: #ffffff;font-size:20px;"></i>
        </div>

        <!-- <div *ngIf="!isHold" class="button-options-call" style="width:10px; display:flex; justify-content:center"
          (click)="onChangeHold(true)">
          <i class="fa-solid fa-play" style="color: #ffffff;font-size:20px;"></i>
        </div>
        <div *ngIf="isHold" class="button-options-call" style="width:10px; display:flex; justify-content:center"
          (click)="onChangeHold(false)">
          <i class="fa-solid fa-pause" style="color: #ffffff;font-size:20px;"></i>
        </div> -->

        <div class="button-options-call" (click)="onChangeDesc()">
          <i class="fa-solid fa-file-lines " style="color: #ffffff;font-size:20px;"></i>
        </div>
      </div>
    </div>
    <div class="time-call-box">
      <div>
        {{ callDuration.format('mm:ss')}}
      </div>
      <div style="font-size:14px; font-weight: 300; height:30px;">
        {{dataOmiCall?.statusTxt == 'invite' ? 'Có cuộc gọi tới' : dataOmiCall?.statusTxt}}
      </div>
    </div>


    <div class="button-call-box" style="margin-top:8px">
      <div
        *ngIf="(dataOmiCall.status == 'connecting' || dataOmiCall.status == 'ringing' ||  dataOmiCall.status == 'connected' ) && (dataOmiCall.typeView == 'OUT_COMMING' || dataOmiCall.typeView == 'IN_COMMING')"
        style="display: flex; justify-content: space-around;align-items: center;">
        <div class="dial-pad-call-button"><span class="call-button-2" (click)="onStop()">
            <img src="../../../../assets/icons/switchboard/reject.png" /></span></div>
      </div>
      <div *ngIf="dataOmiCall.status == 'ended'"
        style="display: flex; justify-content: space-around;align-items: center;">
        <div class="dial-pad-call-button"><span class="call-button-2" (click)="onClose()"><span nz-icon nzType="close"
              nzTheme="outline"></span></span></div>
      </div>
      <div
        *ngIf="dataOmiCall.status == 'invite' && (dataOmiCall.typeView == 'CALLING' || dataOmiCall.typeView == 'IN_COMMING')"
        style="display: flex; justify-content: space-around;align-items: center;">
        <div class="dial-pad-call-button"><span class="call-button-2" (click)="onReject()">
            <img src="../../../../assets/icons/switchboard/reject.png" />
          </span></div>
        <div class="dial-pad-call-button"><span class="call-button-2 call-button-accept" (click)="onAccept()">
            <img src="../../../../assets/icons/switchboard/accept.png" /></span></div>
      </div>
    </div>
  </div>
  <div class="footer-call">
  </div>
</nz-card>