<div class="header" id="header-top">
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i
            nz-icon
            [nzType]="isFolded ? 'menu-unfold' : 'menu-fold'"
            theme="outline"
          ></i>
        </a>
      </li>
      <li *ngIf="isGiaHan">
        <a *ngIf="!isCollapseExpried" (click)="gotoGiaHan()" class="hanxuly">
          <span nz-icon nzType="warning" nzTheme="outline"></span>
          Tài khoản của bạn <b>còn {{ soNgayHetHan | number }} ngày </b> sử
          dụng, vui lòng gia hạn
        </a>
        
      </li>
    </ul>

    <ul class="nav-right">
      <li *ngIf="!isCollapseLink" class="d-flex">
        <div style="padding: 5px 0px; font-size: 14px">
          <i class="fa-solid fa-earth-americas"></i>
          Đặt lịch:
        </div>
        <p
          nz-typography
          nzCopyable
          [nzCopyText]="linkDatLichDonVi"
          style="margin: 0; padding: 3px 0px"
        >
          <a
            class="link-datlich"
            target="_blank"
            (click)="onLinkDatLichDonVi()"
          >
            {{ linkDatLichDonViText }}
          </a>
        </p>
        <p style="margin: 0px 5px; padding: 3px 0px">|</p>
        <p
          nz-typography
          nzCopyable
          [nzCopyText]="linkDatLichChiNhanh"
          style="margin: 0; padding: 3px 0px"
        >
          <a
            class="link-datlich"
            target="_blank"
            (click)="onLinkDatLichChiNhanh()"
          >
            {{ linkDatLichChiNhanhText }}
          </a>
        </p>
      </li>
      <li>
        <ng-container
          *ngIf="(isAdmin || isAdNhieuChiNhanh) && listChiNhanh.length > 0"
        >
          <a
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="menu"
            class="chinhanh-dropdown"
          >
            <span nz-icon nzType="shop" nzTheme="outline"></span>
            <span class="m-l-5 m-r-5">{{ userLogin.tenChiNhanh }}</span>
            <span nz-icon nzType="down"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li
                nz-menu-item
                *ngFor="let option of listChiNhanh"
                (click)="onChangeChiNhanh(option)"
              >
                <span nz-icon nzType="shop" nzTheme="outline"></span>
                <span class="m-l-5 m-r-5">{{ option.tenChiNhanh }}</span>
              </li>
            </ul>
          </nz-dropdown-menu>
          <!-- <nz-select [nzSize]="'small'"  style="width: 250px;" nzPlaceHolder="Chọn chi nhánh" [(ngModel)]="idChiNhanh" (ngModelChange)="onChangeChiNhanh()" [nzCustomTemplate]="defaultTemplate">
                        <nz-option *ngFor="let option of listChiNhanh" [nzLabel]="option.tenChiNhanh" [nzValue]="option.id"></nz-option>
                      </nz-select>
                      <ng-template #defaultTemplate let-selected>
                        <span nz-icon nzType="shop" nzTheme="outline"></span>
                        {{ selected.nzLabel }}
                      </ng-template> -->
        </ng-container>
      </li>
      <li>
        <a
          nz-dropdown
          nzTrigger="click"
          [nzDropdownMenu]="popNotification"
          (nzVisibleChange)="changeViewNotification($event)"
          [nzPlacement]="'bottomRight'"
        >
          <nz-badge [nzCount]="soThongBao">
            <i nz-icon nzType="bell" theme="outline"></i>
          </nz-badge>
        </a>
        <nz-dropdown-menu #popNotification="nzDropdownMenu">
          <div nz-menu class="pop-notification">
            <div
              class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-dark font-weight-semibold m-b-0">
                <i nz-icon nzType="bell" theme="outline"></i>
                <span class="m-l-10">Thông báo</span>
              </p>
              <a
                href="javascript:void(0)"
                *ngIf="soThongBao > 0"
                (click)="readAllNotification(true)"
                class="p-v-5"
                >Đã xem</a
              >
            </div>
            <div
              class="content-container"
              fxLayout="column"
              fxLayoutAlign="stretch"
              fxFlex="auto"
            >
              <perfect-scrollbar [config]="config">
                <nz-list
                  class="ant-list-item-links"
                  [nzDataSource]="notificationList"
                  [nzRenderItem]="item"
                  [nzItemLayout]="'horizontal'"
                >
                  <ng-template #item let-item>
                    <nz-list-item
                      [style]="item.isView ? '' : 'background-color: aliceblue'"
                      class="notification-item"
                    >
                      <a
                        href="javascript:void(0)"
                        (click)="readNotification(item)"
                        *ngIf="item.url"
                        class="p-b-10"
                      >
                        <div class="d-flex">
                          <nz-avatar
                            *ngIf="item.image"
                            [nzSrc]="item.image"
                          ></nz-avatar>
                          <div class="m-l-15" style="flex: 1">
                            <p class="m-b-0 text-dark">{{ item.content }}</p>
                            <p class="m-b-0">
                              <small>{{ item.time }}</small>
                            </p>
                          </div>
                        </div>
                      </a>
                      <a
                        href="javascript:void(0)"
                        *ngIf="!item.url"
                        (click)="readNotification(item)"
                        class="p-b-10"
                      >
                        <div class="d-flex">
                          <nz-avatar
                            *ngIf="item.image"
                            [nzSrc]="item.image"
                          ></nz-avatar>
                          <div class="m-l-15" style="flex: 1">
                            <p class="m-b-0 text-dark">{{ item.content }}</p>
                            <p class="m-b-0">
                              <small>{{ item.time }}</small>
                            </p>
                          </div>
                        </div>
                      </a>
                    </nz-list-item>
                  </ng-template>
                </nz-list>
              </perfect-scrollbar>
            </div>
          </div>
        </nz-dropdown-menu>
      </li>
      <li>
        <span
          class="p-h-10 pointer"
          nz-dropdown
          [nzDropdownMenu]="profile"
          [nzTrigger]="'click'"
          [nzPlacement]="'bottomRight'"
        >
          <nz-avatar [nzSrc]="userAvatar"></nz-avatar>
        </span>
        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu class="p-b-15 p-t-20">
            <li class="p-h-20 p-b-15 m-b-10 border-bottom">
              <div class="d-flex m-r-50">
                <nz-avatar nzSize="large" [nzSrc]="userAvatar"></nz-avatar>
                <div class="m-l-10">
                  <p class="m-b-0 text-dark font-weight-semibold">
                    {{ userLogin?.displayName }}
                  </p>
                  <p class="m-b-0 opacity-07">{{ userLogin?.userName }}</p>
                </div>
              </div>
            </li>
            <li nz-menu-item (click)="onCapNhatThongTin($event)">
              <a
                class="p-v-5 d-flex align-items-center justify-content-between"
              >
                <div>
                  <i
                    class="opacity-04 font-size-16"
                    nz-icon
                    nzType="user"
                    theme="outline"
                  ></i>
                  <span class="m-l-10">Cập nhật thông tin</span>
                </div>
                <i
                  class="font-size-10"
                  nz-icon
                  nzType="right"
                  theme="outline"
                ></i>
              </a>
            </li>
            <li nz-menu-item (click)="onChangePass($event)">
              <a
                class="p-v-5 d-flex align-items-center justify-content-between"
              >
                <div>
                  <i
                    class="opacity-04 font-size-16"
                    nz-icon
                    nzType="lock"
                    theme="outline"
                  ></i>
                  <span class="m-l-10">Đổi mật khẩu</span>
                </div>
                <i
                  class="font-size-10"
                  nz-icon
                  nzType="right"
                  theme="outline"
                ></i>
              </a>
            </li>
            <li nz-menu-item (click)="logout()">
              <a
                class="p-v-5 d-flex align-items-center justify-content-between"
              >
                <div>
                  <i
                    class="opacity-04 font-size-16"
                    nz-icon
                    nzType="logout"
                    theme="outline"
                  ></i>
                  <span class="m-l-10">Logout</span>
                </div>
                <i
                  class="font-size-10"
                  nz-icon
                  nzType="right"
                  theme="outline"
                ></i>
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
    </ul>
  </div>
</div>

<ng-template #titleTemplate>
  <span nz-icon nzType="close"></span>
  Title
</ng-template>
<ng-template #contentTemplate>
  <span nz-icon nzType="check"></span>
  Content
</ng-template>


